import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "./swiper.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import {
  EffectFade,
  Navigation,
  Pagination,
  Autoplay,
  EffectCoverflow,
  EffectCreative,
} from "swiper/modules";
import AOS from "aos";
import "aos/dist/aos.css";
import Button from "../Button/Button";
import Chart from "./Chart";
import { Circle, Facebook, Flag, Linkedin, Phone, Twitter } from "react-feather";
import Second from "../../assets/images/home/thirdSection/2.jpg";
import Banner from "../../assets/images/blog/user-img-1.jpg";
import tables from "../../assets/images/blog/tables.jpg";
import img3 from "../../assets/images/blog/blog-titleimg.jpg";
import ImageReactangle from "../Contact/ImageReactangle";
import Image from "../Contact/Image";
import Footer from "../footer";
import Logo from "../../assets/images/logo.png";
import B1 from "../../assets/images/flags/air-canada-logo-tail.png";
import B2 from "../../assets/images/flags/alliance-air-logo-tail.png";
import B3 from "../../assets/images/flags/american-airlines-logo-tail.png";
import B4 from "../../assets/images/flags/british-airways-logo-tail.png";
import B5 from "../../assets/images/flags/cathay-pacific-logo-tail.png";
import B6 from "../../assets/images/flags/delta-logo-tail.png";
import B7 from "../../assets/images/flags/easyjet-logo-tail.png";
import B8 from "../../assets/images/flags/emirates-logo-tail.png";
import B9 from "../../assets/images/flags/etihad-airways-logo-tail.png";

import B11 from "../../assets/images/flags/jetblue-logo-tail.png";

import B13 from "../../assets/images/flags/lufthansa-logo-tail.png";
import B14 from "../../assets/images/flags/pegasus-airlines-logo-tail.png";
import B15 from "../../assets/images/flags/qantas-logo-tail.png";
import B16 from "../../assets/images/flags/qatar-airways-logo-tail.png";
import B17 from "../../assets/images/flags/ryanair-uk-logo-tail.png";
import B18 from "../../assets/images/flags/saudia-logo-tail.png";
import B19 from "../../assets/images/flags/singapore-airlines-logo-tail.png";
import B20 from "../../assets/images/flags/turkish-airlines-logo-tail.png";
import B21 from "../../assets/images/flags/united-airlines-logo-tail.png";
import B22 from "../../assets/images/flags/wizz-air-logo-tail.png";

import planDep from "../../assets/images/banner/planeDep.svg";
import planAri from "../../assets/images/banner/planeAri.svg";
import HeadingSep from "../../assets/images/banner/heading-sep.png";
import cusImg from "../../assets/images/banner/customize-umrah.png";
import Datepicker from "./Datepicker";
import service from "../../Service/Service";
import CustomizeSection from "./CustomizeSection";
import Navbar from "../navbar";
import InputSection from "./InputSection";
import { Link } from "react-router-dom";
import CardsSection from "./CardsSection";
import CategorySection from "./CategorySection";
import { Compass, CreditCard, MapPin, MonitorCog, PhoneCall, Share, Share2, Umbrella } from "lucide-react";
import BannerSection from "./BannerSection";
import parse from 'html-react-parser';

const TopSection = (props) => {
  const [categories, setCategories] = useState([]);
  const [packages, setPackages] = useState([]);
  const [airportDep, setAirportDep] = useState([]);
  const [airportArv, setAirportArv] = useState([]);
  const [paras, setParas] = useState([]);
  const getCategories = () => {
    service.getAllCategory().then((response) => {
      setCategories(response.data);
    });
  };

  const getPackages = () => {
    service.getAllpackage().then((response) => {
      let pack = response.data.filter((x) => x.featured == 1);
      setPackages(response.data);
    });
  };

  const getAllAirports = () => {
    service.getAllAirports().then((response) => {
      let dep = response.data.filter((x) => x.type == "DEPARTURE");
      let arv = response.data.filter((x) => x.type == "ARRIVAL");
      setAirportDep(dep);
      setAirportArv(arv);
    });
  };
  
  const getAllparas = () => {
    service.getAllparas().then((response) => {
      let dep = response.data.filter((x) => x.package_id == '0');
      setParas(dep)
     
    });
  };

  useEffect(() => {
    AOS.init();
    getCategories();
    getPackages();
    getAllAirports();
    getAllparas();
    localStorage.clear();
  }, []);

  return (
    <div>
     <BannerSection/>

<BackSwiper/>


<div className="w-[80%] mx-auto mt-10">
<p className="tracking-[3px] text-2xl md:text-3xl font-[600]  leading-12 mt-0 text-center">
Why To Choose Us
          </p>
          <p className="tracking-[2px] text-lg md:text-xl font-[500] leading-8 mt-2 text-center ">
          We Are Known For Travel Perfection
          </p>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-5">
        
<div
  class="m-2 group px-10 py-5 bg-white/10 rounded-lg flex flex-col items-center justify-center gap-2 relative after:absolute after:h-full after:bg-[#22c55e] z-20 shadow-lg after:-z-20 after:w-full after:inset-0 after:rounded-lg transition-all duration-300 hover:transition-all hover:duration-300 after:transition-all after:duration-500 after:hover:transition-all after:hover:duration-500 overflow-hidden cursor-pointer after:-translate-y-full after:hover:translate-y-0 [&amp;_p]:delay-200 [&amp;_p]:transition-all"
>
 <Flag className=" tracking-[2px] text-lg md:text-xl font-[500] leading-8 mt-2 text-center" size={40}/>
 <p className=" tracking-[2px] text-lg md:text-xl font-[500] leading-6 mt-2 text-center">Tell Us What You Want To Do</p>
</div>

<div
  class="m-2 group px-10 py-5 bg-white/10 rounded-lg flex flex-col items-center justify-center gap-2 relative after:absolute after:h-full after:bg-[#22c55e] z-20 shadow-lg after:-z-20 after:w-full after:inset-0 after:rounded-lg transition-all duration-300 hover:transition-all hover:duration-300 after:transition-all after:duration-500 after:hover:transition-all after:hover:duration-500 overflow-hidden cursor-pointer after:-translate-y-full after:hover:translate-y-0 [&amp;_p]:delay-200 [&amp;_p]:transition-all"
>
 <MapPin className=" tracking-[2px] text-xl font-[500] leading-8 mt-2 text-center" size={40}/>
 <p className=" tracking-[2px] text-xl font-[500] leading-6 mt-2 text-center">Share Your Travel Locations</p>
</div>

<div
  class="m-2 group px-10 py-5 bg-white/10 rounded-lg flex flex-col items-center justify-center gap-2 relative after:absolute after:h-full after:bg-[#22c55e] z-20 shadow-lg after:-z-20 after:w-full after:inset-0 after:rounded-lg transition-all duration-300 hover:transition-all hover:duration-300 after:transition-all after:duration-500 after:hover:transition-all after:hover:duration-500 overflow-hidden cursor-pointer after:-translate-y-full after:hover:translate-y-0 [&amp;_p]:delay-200 [&amp;_p]:transition-all"
>
 <Share2 className=" tracking-[2px] text-lg md:text-xl font-[500] leading-8 mt-2 text-center" size={40}/>
 <p className=" tracking-[2px] text-lg md:text-xl font-[500] leading-6 mt-2 text-center">
 Share Your Travel Preference</p>
</div>

<div
  class="m-2 group px-10 py-5 bg-white/10 rounded-lg flex flex-col items-center justify-center gap-2 relative after:absolute after:h-full after:bg-[#22c55e] z-20 shadow-lg after:-z-20 after:w-full after:inset-0 after:rounded-lg transition-all duration-300 hover:transition-all hover:duration-300 after:transition-all after:duration-500 after:hover:transition-all after:hover:duration-500 overflow-hidden cursor-pointer after:-translate-y-full after:hover:translate-y-0 [&amp;_p]:delay-200 [&amp;_p]:transition-all"
>
 <Compass className=" tracking-[2px] text-lg md:text-xl font-[500] leading-8 mt-2 text-center" size={40}/>
 <p className=" tracking-[2px] text-lg md:text-xl font-[500] leading-6 mt-2 text-center">
 100% Trusted Travel Agency</p>
</div>

          </div>
</div>

      <div className="my-[70px] w-[90%] md:w-[80%] mx-auto grid grid-cols-1 md:grid-cols-4 gap-5">
        {packages.map((x) => {
          return <CategorySection data={x} />;
        })}
      </div>

      <div className="my-[70px] w-[90%] md:w-[80%] mx-auto grid grid-cols-1">
        {paras.map((x) => {
          return parse(x.description)
        })}
      </div>
      
      <Footer />
    </div>
  );
};

const BackSwiper = () => {

  let list =[
    B1 ,
B2 ,
B3 ,
B4 ,
B5 ,
B6 ,
B7 ,
B8 ,
B9 ,
B11,
B13,
B14,
B15,
B16,
B17,
B18,
B19,
B20,
B21,
B22,
  ]
  return (
    <div className="mx-auto  w-[80%] h-full mt-10">
     
      {/* <Swiper
      slidesPerView={3}
        spaceBetween={30}
        effect={"fade"}
        navigation={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        className="mySwiper"
      > */}
<Swiper
        slidesPerView={5}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[ Navigation, Pagination, Autoplay]}
        className="mySwiper"
      >


        {
          list.map(e=>{
            return         <SwiperSlide>
            <img src={e} className="h-full w-full object-cover my-4" />
          </SwiperSlide>
          })
        }

        
      </Swiper>
    </div>
  );
};

export default TopSection;
